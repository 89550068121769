import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { css } from '@emotion/core';
import { PaymentAmountRadioOptions, PaymentFormLabels, OtherPaymentAmountError } from './paymentConstants';
import formatCurrency from '../../utils/format-currency';
import { handleOtherAmountOnChange } from './otherAmountHelpers';
import {
  Box,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  TextField,
  Typography,
} from '@material-ui/core';

const PaymentAmountOptions = ({
  totalAmountDue,
  selectedPaymentAmountOption,
  handlePaymentAmountOptionsOnChange,
  handleOtherPaymentAmountOnChange,
  handlePaymentAmountValidation,
}) => {
  const [otherAmount, setOtherAmount] = useState(0);
  const [otherAmountWithFormat, setOtherAmountWithFormat] = useState('');
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(' '); // space added to prevent bouncing

  useEffect(() => {
    handlePaymentAmountValidation(hasError);
  }, [hasError, handlePaymentAmountValidation]);

  const clearError = () => {
    setHasError(false);
    setErrorMessage(' ');
  };

  const handleRadioOnChange = (event) => {
    clearError();
    handlePaymentAmountOptionsOnChange(event.target.value);
  };

  const validateOtherAmountOnBlur = () => {
    if (selectedPaymentAmountOption === PaymentAmountRadioOptions.FULL_ACCOUNT_BALANCE) {
      return;
    }

    if (otherAmount < 1) {
      setHasError(true);
      setErrorMessage(OtherPaymentAmountError.MINIMUM_REQUIRED);
      return;
    }
    if (otherAmount >= 1 && otherAmount > totalAmountDue) {
      setHasError(true);
      setErrorMessage(OtherPaymentAmountError.MAXIMUM_EXCEEDED);
    } else {
      setHasError(false);
    }
  };

  const onChangeOtherAmount = (event) => {
    const { displayString, numberValue } = handleOtherAmountOnChange(event);

    if (displayString === '') {
      setOtherAmountWithFormat('');
    } else {
      setOtherAmountWithFormat(displayString);
    }

    setOtherAmount(numberValue);
    handleOtherPaymentAmountOnChange(numberValue);
  };

  return (
    <FormControl fullWidth={true} data-testid="payment-amount-container">
      <FormLabel id="payment-amount-label">
        <Typography
          variant="h5"
          component="span"
          css={css`
            color: #000;
          `}
        >
          {PaymentFormLabels.PAYMENT_AMOUNT}
        </Typography>
      </FormLabel>
      <RadioGroup
        data-testid="payment-amount-group"
        aria-labelledby="payment-amount-label"
        value={selectedPaymentAmountOption}
        name="payment-amount-group"
        onChange={handleRadioOnChange}
        onBlur={validateOtherAmountOnBlur}
        row
      >
        <Box sx={{ display: 'flex', gap: '40px', marginTop: '16px' }}>
          <Box>
            <Box>{PaymentAmountRadioOptions.FULL_ACCOUNT_BALANCE}</Box>
            <FormControlLabel
              data-testid="full-balance-label"
              value={PaymentAmountRadioOptions.FULL_ACCOUNT_BALANCE}
              control={<Radio />}
              label={formatCurrency(totalAmountDue)}
            />
          </Box>
          <Box>
            <Box>{PaymentAmountRadioOptions.OTHER_AMOUNT}</Box>
            <FormControlLabel
              data-testid="other-amount-label"
              value={PaymentAmountRadioOptions.OTHER_AMOUNT}
              control={<Radio />}
              label={
                <TextField
                  inputProps={{ 'data-testid': 'other-amount-input' }}
                  placeholder="$0.00"
                  value={otherAmountWithFormat}
                  onChange={onChangeOtherAmount}
                  onFocus={() => handlePaymentAmountOptionsOnChange(PaymentAmountRadioOptions.OTHER_AMOUNT)}
                  onBlur={validateOtherAmountOnBlur}
                  error={hasError}
                  helperText={hasError ? errorMessage : ' '}
                />
              }
            />
          </Box>
        </Box>
      </RadioGroup>
    </FormControl>
  );
};

PaymentAmountOptions.propTypes = {
  totalAmountDue: PropTypes.number.isRequired,
  selectedPaymentAmountOption: PropTypes.string.isRequired,
  handlePaymentAmountOptionsOnChange: PropTypes.func.isRequired,
  handleOtherPaymentAmountOnChange: PropTypes.func.isRequired,
  handlePaymentAmountValidation: PropTypes.func.isRequired,
};

export default PaymentAmountOptions;

/**
 * Handles the change event for the "other amount" input field.
 * Cleans, validates, and formats the input value before returning an object with display string and number value.
 *
 * @param {Event} event - The change event object triggered on the input field.
 * @returns {Object} An object containing the formatted display string and number value.
 *   @property {string} displayString - The formatted currency string with a dollar sign and up to two decimal places.
 *   @property {number} numberValue - The parsed number value of the input.
 */
export const handleOtherAmountOnChange = (event) => {
  const cleanValue = event.target.value
    .replace(/[^\d.]/g, '') // Remove non-digit and non-decimal characters
    .replace(/^0{2,}/g, '0') // Trim leading zeros
    .replace(/(\..*)\./g, '$1') // Remove duplicate decimals
    .replace(/^\./, '0.'); // Add leading zero before decimal if needed;

  // Validate and format the value
  const formattedValue = formatAndValidate(cleanValue);

  return formattedValue; // Return the formatted object
};

/**
 * Formats and validates the provided value as a currency amount.
 * - **Note:** This function is extracted and exported for testing purposes. It is not intended to be used on its own.
 *
 * @param {string} value - The input value to be formatted and validated.
 * @returns {Object} An object containing the formatted display string and number value.
 *   @property {string} displayString - The formatted currency string with a dollar sign and up to two decimal places.
 *   @property {number} numberValue - The parsed number value of the input.
 */
export function formatAndValidate(value) {
  if (!value || isNaN(+value)) {
    return {
      displayString: '',
      numberValue: 0,
    };
  }

  let validValueString = value;

  if (value.includes('.')) {
    const parts = value.split('.');
    if (parts.length > 2 || parts[1].length > 2) {
      validValueString = parts[0] + '.' + parts[1].substring(0, 2);
    }
  }

  return {
    displayString: `$${validValueString}`,
    numberValue: Number(validValueString),
  };
}

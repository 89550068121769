import React from 'react';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import CollapsableCard from '../components/CollapsableCard';
import Typography from '@material-ui/core/Typography';
import { css } from '@emotion/core';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Brightness1 from '@material-ui/icons/Brightness1';
import ArrowCircleRightIcon from '../components/icons/ArrowCircleRight';
import { useUserAuthInfo } from '../components/auth';

const STEP_STATUS = {
  COMPLETE: 'Complete',
  OPEN: 'Open',
  HOLD: 'On-Hold',
};

function getStatusIcon(status) {
  let statusIcon = <Brightness1 htmlColor="#DBDCE5" />;

  if (status === STEP_STATUS.COMPLETE) {
    statusIcon = <CheckCircle htmlColor="#56A45A" />;
  }

  if (status === STEP_STATUS.OPEN) {
    statusIcon = <ArrowCircleRightIcon htmlColor="#007505" />;
  }

  if (status === STEP_STATUS.HOLD) {
    statusIcon = <ArrowCircleRightIcon htmlColor="#EE8C66" />;
  }

  return statusIcon;
}

const CardBodySection = ({ subtitle, content }) => {
  if (!content) {
    return null;
  }

  const Content = () => {
    if (typeof content === 'string') {
      return <Typography variant="body1">{content}</Typography>;
    } else if (Array.isArray(content)) {
      return content.map((item, index) => <DescriptionText key={index} text={item} />);
    } else {
      return content;
    }
  };

  return (
    <div
      data-testid={subtitle}
      css={css`
        margin-bottom: 24px;
      `}
    >
      <Typography
        variant="subtitle2"
        css={css`
          text-transform: uppercase;
        `}
      >
        {subtitle}
      </Typography>
      <Content />
    </div>
  );
};

CardBodySection.propTypes = {
  subtitle: PropTypes.string.isRequired,
  content: PropTypes.any,
};

export const ProjectStatusStepCard = ({ step, timezone }) => {
  const { title, status, description, relatedWfmTaskNames, subSteps } = step;
  const { isAdmin } = useUserAuthInfo();

  const headerWithStatusIcon = () => {
    const statusIcon = getStatusIcon(status);

    return (
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 16px;
        `}
      >
        {statusIcon}
        {title}
      </div>
    );
  };

  return (
    <CollapsableCard
      title={headerWithStatusIcon()}
      key={title}
      initiallyExpanded={[STEP_STATUS.OPEN, STEP_STATUS.HOLD].includes(status) ? true : false}
      css={css`
        margin-bottom: 16px;
      `}
    >
      <CardBodySection subtitle="Status" content={status} />

      <CardBodySection
        subtitle="DESCRIPTION: CUSTOMER-FACING"
        content={<Description description={description} timezone={timezone} subSteps={subSteps} />}
      />
      {isAdmin && <CardBodySection subtitle="Related Task Names" content={relatedWfmTaskNames} />}
    </CollapsableCard>
  );
};

ProjectStatusStepCard.propTypes = {
  step: PropTypes.any,
  timezone: PropTypes.string,
};

const subStepIconWrapperCss = css`
  display: flex;
  margin-right: 5px;

  // This is a hack. The icon SVGs are from internal components and external libraries.
  // They have varied width/height prop controls, not to mentio viewbox, etc.
  // Instead, this css shrinks the entire SVG icon by 20%
  & > svg {
    transform: scale(0.8);
  }
`;

function SubStep({ step, timezone }) {
  const statusIcon = getStatusIcon(step.status);
  return (
    <li css={{ listStyleType: 'none', margin: '0', padding: '5px 0px', fontSize: '1em' }}>
      {' '}
      <div css={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <div css={subStepIconWrapperCss}>{statusIcon}</div>
        <span css={{ fontSize: '1.2em' }}>{step.title}</span>
      </div>
      <div css={{ marginLeft: '30px' }}>
        {step?.description?.map((text, index) => <DescriptionText key={index} text={text} />) || null}
        {step?.appointments?.map((appointment, index) => (
          <Appointment key={index} appointment={appointment} timezone={timezone} />
        )) || null}
      </div>
    </li>
  );
}

SubStep.propTypes = {
  step: PropTypes.shape({
    status: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.array,
    appointments: PropTypes.array,
  }),
  timezone: PropTypes.string,
};

function Description({ subSteps, description, timezone }) {
  if (!subSteps) {
    return null;
  }

  return (
    <>
      <div>{description?.map((item, index) => <DescriptionText key={index} text={item} />) || null}</div>
      <ul css={{ listStyleType: 'none', margin: '0', padding: '0' }}>
        {subSteps.map((step, index) => {
          return <SubStep key={index} step={step} timezone={timezone} />;
        })}
      </ul>
    </>
  );
}

Description.propTypes = {
  subSteps: PropTypes.array,
  description: PropTypes.array,
  timezone: PropTypes.string,
};

const DescriptionText = ({ text }) => {
  return (
    <Typography
      css={css`
        font-size: 1em;
        font-family: Untitled Sans;
        font-weight: 400;
        line-height: 1.5;
        display: block;
        margin-bottom: 6px;
      `}
    >
      {text}
    </Typography>
  );
};

DescriptionText.propTypes = {
  text: PropTypes.string,
};

const Appointment = ({ appointment, timezone }) => {
  const { title, arrivalWindowStartTime, arrivalWindowEndTime } = appointment;
  const showAppointmentData = title && arrivalWindowStartTime && timezone;
  if (!showAppointmentData) {
    return (
      <>
        <Typography css={css``}>Unable to show appointment information. Check Salesforce data.</Typography>
      </>
    );
  }

  const appointmentStartDateTime = DateTime.fromISO(arrivalWindowStartTime, { zone: timezone });
  const appointmentEndDateTime = arrivalWindowEndTime
    ? DateTime.fromISO(arrivalWindowEndTime, { zone: timezone })
    : undefined;
  const startHour = appointmentStartDateTime.toLocaleString(DateTime.TIME_SIMPLE);
  const endHour = appointmentEndDateTime ? appointmentEndDateTime.toLocaleString(DateTime.TIME_SIMPLE) : undefined;
  const firstLine = `${title} - Date: ${appointmentStartDateTime.toLocaleString({
    ...DateTime.DATE_HUGE,
    hourCycle: 'h11',
  })}`;
  const secondLine = `Arrival time window: ${startHour}` + (endHour ? ` - ${endHour}` : '');

  return (
    <>
      <Typography>{firstLine}</Typography>
      <Typography>{secondLine}</Typography>
    </>
  );
};

Appointment.propTypes = {
  appointment: PropTypes.shape({
    title: PropTypes.string,
    arrivalWindowEndTime: PropTypes.string,
    arrivalWindowStartTime: PropTypes.string,
  }),
  timezone: PropTypes.string,
};
